<template>
  <WidgetFrame @onResize="onResize">
    <template v-slot:title>
        {{ $t('permission.department_staff') }}
    </template>
    <template v-slot:content>
      <template v-if="!canView('DEPARTMENT', ['STAFF']) || !canView('DEPARTMENT')">
        <div class="center-text">
          {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
        </div>
      </template>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else-if="noDepartments">
        <div class='status-message'>No departments</div>
      </template>
      <template v-else>
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text" for="dateEnd">
              {{$t('dashboard.widgets.date_range')}}
            </label>
          </div>
          <b-form-select id="dateEnd" 
            :disabled="!canView('STAFF', ['startDate', 'endDate'])"
            :data-vv-as="$t('dashboard.widgets.date_range')"
            data-vv-name="dashboard.widgets.date_range"
            data-vv-delay="500"
            :options="optionTypes"
            text-field="label"
            value-field="key"
            v-model="type"
            />
        </div>
        <div class='chart-holder'>
          <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>
        </div>
        <Pager v-if="sourceData.length > pageSize"
          :sourceData="sourceData"
          :pageSize="pageSize"
          :small="width < 450"
          @paged="onPageChanged"/>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import {AgChartsVue} from 'ag-charts-vue';
import { departmentService } from '@/services';
import settings from "@/_dashboardSettings";
import { EventBus } from '@/helpers';
import { tooltipRenderer } from '@/helpers/ag-chart-tooltip-renderer';

export default {
  name: 'HomeDepartmentStaffWidget',
  components: {
    WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    Pager: () => import('@/components/Pager'),
    AgChartsVue,
  },
  props: {
    profile:    { type: Object, required: true },
  },
  watch: {
    type(newValue, oldValue) {
      if (oldValue != null) {
        this.saveSettings();
        this.buildData();
        this.buildChart();
      }
    }
  },
  computed: {
    optionTypes() {
      return [
        {key: 'today', label: this.$t('date.today')},
        {key: 'plus_3m', label: this.$t('dashboard.plus_3_months')},
        {key: 'plus_6m', label: this.$t('dashboard.plus_6_months')},
        {key: 'plus_12m', label: this.$t('dashboard.plus_12_months')},
      ];
    },
    companyrule() {
      if (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds) {
        const companies = this.$store.state.company.filterIds;
        const companyrule = ['DEPARTMENT.COMPANY.uuId', 'within', companies.join('|')];
        return companyrule
      }
      return null;
    },
  },
  data: function() {
    return {
      orientation: null,
      width: 0,
      height: 0,
      loaded: false,
      noDepartments: false,
      options: null,
      departmentData: {},
      sourceData: [],
      departments: null,
      type: null,
      targetDay: null,
      pageSize: 25,
      page: 0,
    }
  },
  created() {
    EventBus.$on('theme-change', () => {
      if (this.loaded) {
        this.buildChart();
      }
    });
    // Use 'today' default type if profile doesn't have a valid key
    const pType = this.profile.optionType;
    this.type = !(this.optionTypes.map(t => t.key).includes(pType)) ? 'today' : pType;
    const self = this;
    if (self.canView('DEPARTMENT')) {
      departmentService.listStaffInDepartments({companyrule: this.companyrule}).then((response) => {
        self.departments = response.data;
        self.buildData();
        this.buildChart();
        this.loaded = true;
      })
      .catch(e => {
        // eslint-disable-next-line
        console.error(e);
      });
    }
  },
  beforeDestroy() {
    EventBus.$off('theme-change');
  },
  methods: {
    buildData() {
      this.departmentData = {};
      this.page = 0;

      // Build our comparison date
      this.targetDay = moment();
      if (this.type == 'plus_3m') {
        this.targetDay.add(3, 'M');
      } else if (this.type == 'plus_6m') {
        this.targetDay.add(6, 'M');
      } else if (this.type == 'plus_12m') {
        this.targetDay.add(12, 'M');
      }
      this.targetDay.startOf('day');

      if (this.departments.length == 0) {
        this.noDepartments = true;
      }

      let self = this;
      this.departments.forEach(d => {
        // There can be multiple deparments with same name, so check first
        if (!(d.name in self.departmentData)) {
          self.departmentData[d.name] = 0;
        }

        // Now consider every staff in the department
        if (self.canView('STAFF', ['startDate', 'endDate'])) {
          if (d.startDate) {
            for (var i = 0; i < d.startDate.length; i++) {
              if (!d.genericStaff[i]) {
                const startDate = d.startDate[i];
                const endDate = d.endDate[i];
                
                var giveStart = (!startDate || startDate == "1970-01-01");
                var giveEnd = (!endDate || endDate == "3000-01-01");
                var staffStart = moment(startDate).startOf('day');
                var staffEnd = moment(endDate).startOf('day');
      
                if ((giveStart || staffStart.isSameOrBefore(self.targetDay)) &&
                    (giveEnd || staffEnd.isSameOrAfter(self.targetDay))) {
                      self.departmentData[d.name]++;
                }
              }
            }
          }
        }
        else {
          self.departmentData[d.name] = d.staffNames.length;
        }
      });

    },
    buildChart() {
      this.sourceData = [];
      var highest = 1;
      for (var name in this.departmentData) {
        const count = this.departmentData[name];
        this.sourceData.push({'name': name, 'count': count});
        highest = Math.max(count, highest);
      }

      this.sourceData.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      this.options = settings.getBarChartOptions(this.width);
      // Only use the portion of the dataset we've paged into
      let start = this.page * this.pageSize;
      let end = Math.min(start + this.pageSize, this.sourceData.length);
      this.options.data = this.sourceData.slice(start, end);

      this.options.title.text = this.sourceData.length + ' Departments';
      this.options.series[0].xKey = 'name';
      this.options.series[0].yKeys = ['count'];
      this.options.series[0].yNames = ['Staff Count'];
      this.options.axes[0].title = {
        enabled: true,
        text: this.$t('location.field.headCount')
      };
      this.options.axes[0].min = 0;
      this.options.axes[0].max = highest;
      this.options.legend.enabled = false;
      
      this.options.series[0].tooltip = {
        enabled: true,
        renderer: tooltipRenderer
      }
    },
    saveSettings() {
      this.profile.optionType = this.type;
      this.$emit('saveWidget', this.profile);
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
      // 35 pixels per row, round up to even number
      this.pageSize = Math.floor(this.height / 35);
      if (this.loaded) {
        this.buildChart();
      }
    },
    onPageChanged(page) {
      this.page = page;
      this.buildChart();
    }
  },
}
</script>

<style lang="scss" scoped>
  .center-text {
    text-align: center;
    margin: auto;
  }
</style>
